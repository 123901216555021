import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {ConfigModule} from '@ngmedax/config';
import {RegistryModule} from '@ngmedax/registry';
import {ValueModule} from '@ngmedax/value';
import {ErrorModule} from '@ngmedax/error';
import {LayoutModule, LayoutService} from '@ngmedax/layout';
import {ThemeModule} from '@ngmedax/theme';
import {ToastModule} from '@ngmedax/toast';
import {ConfigGeneratorModule, ConfigGeneratorCrudComponent} from '@ngmedax/config-generator';

import {config} from '../config';
import {modifier} from '../modifier';
import {routing} from './app.routing';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    /* mandatory core modules! */
    BrowserAnimationsModule,
    BrowserModule,
    RegistryModule,
    LayoutModule,
    ErrorModule,
    ValueModule,
    ToastModule.forRoot({alertHack: true, confirmHack: true}),
    ConfigModule.forConfig(config, modifier),

    /* optional feature modules */
    ThemeModule,
    ConfigGeneratorModule,
    routing
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(
    layoutService: LayoutService,
  ) {
    layoutService.setHomeComponent(ConfigGeneratorCrudComponent);
  }
}
