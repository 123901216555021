import {versionInfo} from './version/info';

export const config = {
  mobil: {theme: null},
  previewMode: true,
  // fallback for modules using old config key
  assetServer: '{{assets#cdn}}',
  assets: {
    cdn: '{{apis#baseUri}}',
    api: null
  },
  layout: {
    redirect: {
      hidePath: true
    }
  },
  mobile: {
    frontend: {
      uri: '/app'
    }
  },
  translation: {
    locales: {
      download: {
        uri: '/assets/locales/%locale%.yaml?ts=%ts%'
      }
    }
  },
  configGenerator: {
    src: 'https://templates.mymedax.dev',
    repos: 'kubernetes'
  },
  versions: {
    base64: versionInfo
  }
};


